import type { Equipment } from '@tag/graphql';
import { EquipmentPost } from '@api/types';
import { Operation } from 'fast-json-patch';

/**
 * Add Equipment
 *
 * @param payload Equipment object
 */
export class AddUpdateEquipment {
  static readonly type = '[Equipment] Add';

  constructor(public payload: EquipmentPost) {}
}

/**
 * Add sub assembly
 *
 * @param id Parent Equipment Id
 * @param payload Sub-Assembly object to create
 */
export class AddSubAssembly {
  static readonly type = '[Equipment] Add SubAssembly';

  constructor(public id: string, public payload: any) {}
}

/**
 * Get all Equipments
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetEquipments {
  static readonly type = '[Equipment] Get';

  constructor(public filter?: string) {}
}

/**
 * Get all Equipments
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetEquipment {
  static readonly type = '[Equipment] Get';

  constructor(public id: string) {}
}

/**
 * Update Equipment, this method will dynamically update the cache equipment list and selected equipment.
 *
 * @param id Equipment Id
 * @param patch JSON patch object used to update the Equipment. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateEquipment {
  static readonly type = '[Equipment] Update';

  constructor(public id: string, public patch: Operation[]) {}
}

/**
 * Update Equipments, this method will dynamically update the cache equipment list and selected equipment.
 *
 * @param ids List of equipment Ids
 * @param patch JSON patch object used to update the Equipment. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateEquipments {
  static readonly type = '[Equipment] Update Multiple';

  constructor(public ids: string[], public patch: Operation[]) {}
}

/**
 * Delete Equipment, this method will dynamically update the cache equipment list and set the selected equipment to null.
 *
 * @param id Equipment Id
 */
export class DeleteEquipment {
  static readonly type = '[Equipment] Delete';

  constructor(public id: string) {}
}

/**
 * Delete Equipments, this method will dynamically update the cache equipment list and set the selected equipment to null.
 *
 * @param ids List of equipment Ids
 */
export class DeleteEquipments {
  static readonly type = '[Equipment] Delete Multiple';

  constructor(public ids: string[]) {}
}

/**
 * Set selected Equipment
 *
 * @param payload New Equipment full object.
 */
export class SetSelectedEquipment {
  static readonly type = '[Equipment] Set';

  constructor(public payload: Equipment | null) {}
}

/**
 * Set multiple selected Equipment
 *
 * @param payload List of new Equipment full object.
 */
export class SetSelectedEquipments {
  static readonly type = '[Equipment] Set Multiple';

  constructor(public payload: Equipment[]) {}
}
